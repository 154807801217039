<template>
  <v-card
    dark
    color="red darken-2"
    class="d-flex flex-column align-center">
    <v-card-title class="d-flex flex-column align-center">
      <v-icon size="85" class="py-6">mdi-delete</v-icon>
      {{ $vuetify.lang.t('$vuetify.site.configurators.delete.ui.title') }}
    </v-card-title>
    <v-card-text
      class="text-center">{{ $vuetify.lang.t('$vuetify.site.configurators.delete.ui.description') }}
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer></v-spacer>
      <v-btn
        dark text
        @click="onCancel">
        {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
      </v-btn>
      <v-btn
        dark
        text
        @click="onConfirm">
        {{ $vuetify.lang.t('$vuetify.global.form.btn_confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    onConfirm: {
      type: Function,
      required: true
    }
  },
  data: () => ({}),
  methods: {}
}
</script>
