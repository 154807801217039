<template>
  <div class="families">
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row class="pt-10">
      <v-col cols="12" class="py-0">
        <v-alert
          v-model="ui.alert.enable"
          :dismissible="ui.alert.dismissible"
          :icon="ui.alert.icon"
          :type="ui.alert.type"
          class="mb-0"
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card outlined
                :loading="ui.list.loading"
                :disabled="ui.list.disabled"
                class="rounded-lg overflow-hidden">
          <template slot="progress">
            <v-progress-linear
              color="blue-grey darken-3"
              height="6"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-row
            style="border-radius: 0"
            class="blue py-6 px-sm-9">
            <v-col cols="12" class="pb-0">
              <v-toolbar-title class="title white--text pl-0">
                {{ $vuetify.lang.t('$vuetify.site.configurators.list.ui.title') }}
              </v-toolbar-title>
              <p class="white--text mb-2">{{ $vuetify.lang.t('$vuetify.site.configurators.list.ui.subtitle') }}</p>
            </v-col>
          </v-row>
          <v-card-text class="px-0 pb-0">
            <div class="px-9">
              <div class="row">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="datatable.search"
                    :disabled="ui.list.disabled"
                    :label="$vuetify.lang.t('$vuetify.global.search')"
                    ref="datatable_search"
                  >
                    <v-icon
                      slot="prepend"
                    >
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>
                <v-col
                  class="justify-end pt-7 d-flex">
                </v-col>
              </div>
            </div>
            <v-data-table :headers="datatable.headers"
                          :items="datatable.items"
                          :search="datatable.search"
                          :items-per-page="datatable.items_per_page"
                          :server-items-length="datatable.total.items"
                          :options.sync="datatable.options"
                          class="datatable"
            >
              <template v-slot:item.education_level_id="{ item }">
                <span class="font-weight-bold">
                  {{ item.education_level }}
                </span>
              </template>
              <template v-slot:item.usage_type_id="{ item }">
                <span>
                  {{ item.usage_type }}
                </span>
              </template>
              <template v-slot:item.storage_cabinet_id="{ item }">
                <span>
                  {{ item.storage_type }}
                </span>
              </template>
              <template v-slot:item.os_id="{ item }">
                <span>
                  {{ item.os_type }}
                </span>
              </template>
              <template v-slot:item.screen_size="{ item }">
                <span>
                  {{ item.screen_size_min + '" - ' + item.screen_size_max + '"' }}
                </span>
              </template>
              <template v-slot:item.device_nb="{ item }">
                <span>
                  {{ item.device_nb_min + ' - ' + item.device_nb_max }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span
                  v-bind:key="key"
                  v-for="(action, key) in _.toString(item.actions).split(',')">
                  <v-icon
                    tag="a"
                    v-if="action === 'see'"
                    small
                    class="mr-2"
                    v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : item.id
                    }})"
                  >
                    mdi-eye
                  </v-icon>
                   <v-icon
                     tag="a"
                     v-if="action === 'edit'"
                     small
                     class="mr-2"
                     v-on:click="$router.push({name:'SiteConfiguratorsEdit', params:{
                       id: $router.currentRoute.params.id,
                      config_id : item.id
                    }})"
                   >
                    mdi-pencil
                  </v-icon>
                  <v-dialog
                    v-model="datatable.deletes[item.id]"
                    persistent
                    v-if="action === 'delete'"
                    max-width="320"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        :disabled="item._isUsed"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <dialog-delete
                      :item="item"
                      :on-confirm="()=> { deleteConfigurator(item); datatable.deletes[item.id] = false }"
                      :on-cancel="()=>{datatable.deletes[item.id] = false}"
                    ></dialog-delete>
                  </v-dialog>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue"
              fab
              large
              dark
              class="ml-2"
              :to="{name:'SiteConfiguratorsAdd'}">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ $vuetify.lang.t('$vuetify.global.add') }}
        </v-tooltip>
      </div>
    </v-fab-transition>
  </div>
</template>
<script>

import DialogDelete from './dialog/Delete'

export default {
  components: {
    'dialog-delete': DialogDelete
  },
  data: () => ({
    ui: {
      list: {
        loading: true,
        disabled: true
      },
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    site_name: 'default',
    breadcrumbs: [],
    datatable: {
      search: '',
      search_interval: null,
      headers: [],
      total: {
        items: 0,
        pages: 0
      },
      options: {},
      current_page: 1,
      items_per_page: 5,
      items: [],
      edit: {},
      deletes: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    /**
     * Fetch configurators
     * @return {Promise<void>}
     */
    async fetchConfigurators () {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        const response = await this.axios.get('/site/' + this.$router.currentRoute.params.id + '/configurator/list', {
          params: {
            page: this.datatable.options.page,
            search: this.datatable.search,
            limit: this.datatable.options.itemsPerPage,
            sort: this.datatable.options.sortDesc.reduce((result, field, index) => {
              result[this.datatable.options.sortBy[index]] = field
              return result
            }, {})
          },
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        this.datatable.items = this._.map(response.data.data, (element) => {
          element.actions = ['edit', 'delete']

          return element
        })
        this.datatable.total.pages = response.data.totalPages
        this.datatable.total.items = response.data.totalItems
        this.datatable.current_page = response.data.currentPage

        this.breadcrumbs.find(e => e.id === 1).text = response.data.site
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // site not found, redirect to home
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.site.configurators.list.notification.site_not_found')
                  }
                }
              })
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.list.notification.load'))
              this.datatable.items = []
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    },
    /**
     * Deletes configurator
     * @param item
     * @return {Promise<void>}
     */
    async deleteConfigurator (item) {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        await this.axios.get('/site/' + this.$route.params.id + '/configurator/delete/' + item.id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        await this.fetchConfigurators()

        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.site.configurators.delete.notification.deleted'))
      } catch (e) {
        switch (parseInt(e.response.status)) {
          // failed to delete
          case 405 :
            this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.delete.notification.fail'))
            break
          // configuration doesn't exist
          case 410 :
            this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.delete.notification.not_found'))
            break
          // site not found, redirect to home
          case 404:
            await this.$router.push({
              name: 'Home',
              params: {
                alert: {
                  enable: true,
                  dismissible: true,
                  icon: 'mdi-alert',
                  type: 'warning',
                  message: this.$vuetify.lang.t('$vuetify.site.configurators.delete.notification.site_not_found')
                }
              }
            })
            break
          default :
            this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.delete.notification.unknown'))
            break
        }
        this.Sentry.captureException(e)
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    }
  },
  mounted () {
    this.fetchConfigurators()
    // restore alerts
    if (!this._.isUndefined(this.$router.currentRoute.params.alert)) {
      this.ui.alert = this.$router.currentRoute.params.alert
    }
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchConfigurators',
    'datatable.options': function (data) {
      this.fetchConfigurators()
    },
    'datatable.search': function (data) {
      if (!this._.isNull(this.datatable.search_interval)) clearTimeout(this.datatable.search_interval)

      this.datatable.search_interval = setTimeout(async () => {
        this.datatable.search_interval = null
        await this.fetchConfigurators()
        this.$refs.datatable_search.focus()
      }, 500)
    }
  },
  created () {
    // Define translations
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.site.name'),
        disabled: true
      },
      {
        id: 1,
        text: this.site_name,
        disabled: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.site.configurators.name'),
        disabled: true
      }
    ]
    this.datatable.headers = [
      {
        value: 'education_level_id',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.education')
      },
      {
        value: 'usage_type_id',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.usage'),
        sortable: true
      },
      {
        value: 'storage_cabinet_id',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.storage'),
        sortable: true
      },
      {
        value: 'os_id',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.os'),
        sortable: true
      },
      {
        value: 'screen_size',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.screen_size'),
        sortable: false
      },
      {
        value: 'device_nb',
        text: this.$vuetify.lang.t('$vuetify.site.configurators.list.ui.datatable.device_nb'),
        sortable: false
      },
      {
        text: this.$vuetify.lang.t('$vuetify.global.actions'),
        align: 'right',
        value: 'actions',
        sortable: false
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
  .datatable {
    tr {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        th:first-child, td:first-child {
          padding-left: 36px;
        }

        th:last-child, td:last-child {
          padding-right: 36px;
        }
      }

    }
  }
}
</style>
